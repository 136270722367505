$output-bourbon-deprecation-warnings: false !default;

@import "~bourbon/app/assets/stylesheets/bourbon";
@import "partials/_variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-touchspin/dist/jquery.bootstrap-touchspin.min.css";
@import "partials/_animations";
@import "partials/_mixins";
@import "partials/_fonts";
@import "partials/_commons";
@import "components/alert";
@import "components/brands";
@import "components/drop-down";
@import "components/modals";
@import "components/iqitmegamenu";
@import "components/iqitmegamenu_mobile";
@import "components/notification";
@import "components/search-widget";
@import "components/checkout";
@import "components/customer";
@import "components/forgotten-password";
@import "components/renew-password";
@import "components/progressbar";
@import "components/featuredproducts";
@import "components/categories";
@import "components/products";
@import "components/preloaders";
@import "components/cart";
@import "components/block-cart";
@import "components/block-reassurance";
@import "components/quickview";
@import "components/stores";
@import "components/headers";
@import "components/footers";
@import "components/contact";
@import "components/errors";
@import "components/customization-modal";
@import "components/sitemap";
@import "lib/swiper/swiper";
@import "partials/_rtl";


.popover {
  font-family: inherit;
}

/*** WRAPPER ***/
#wrapper {
  .banner {

  }
  .breadcrumb {
    background: transparent;
    padding: 0;
    display: block;

    &[data-depth="1"] {
      display: none;
    }
    ol {
      padding-left: 0;
      margin-bottom: 0;
    }
    li {
      display: inline;
      &::after {
        content: "/";
        opacity: 0.4;
        margin: .3em;
      }
      &:last-child {
        &::after {
          content: "";
        }
      }
    }
    a:hover {
      opacity: 0.8;
    }
    #iqitproductsnav{
      font-size: 1.5rem;
    }
  }
}
/*** MAIN ***/
#main {
  .page-content {
    margin-bottom: rem(25px);
    h6 {
      margin-bottom: rem(18p);
    }
  }
}


#notifications {
  ul {
    margin-bottom: 0;
  }
}

.block-section{
  margin-bottom: 3rem;
  clear: both;
}

.section-title{
  position: relative;
}

/*** THEME COMPONTENTS ***/
#back-to-top{
  border: none;
  cursor: pointer;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  opacity: 0;
  visibility: hidden;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  transition: $default-transition;
  font-size: 2rem;
  outline: none;
  z-index: 120;
  &.-back-to-top-visible{
    opacity: 1;
    visibility: visible;
  }
}

#page-preloader{
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: white;
}

/*** Responsive part ***/
@include media-breakpoint-down(sm) {
  #checkout-cart-summary {
    float: none;
    width: 100%;
    margin-top: 1rem;
  }
  #header {
  }
  section.checkout-step {
    width: 100%;
  }
  .default-input {
    min-width: 100%;
  }
  label {
    clear: both;
  }
}
@include media-breakpoint-down(md) {
  .container {
    max-width: 100%;
  }
}



/*** OLD BS-fixes***/
#_mobile_blockcart-content.dropdown-menu,
#_mobile_iqitmegamenu-mobile.dropdown-menu{
border: none !important;
  background: none !important;
}
.card-block {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem;
}
.col-xs-12{
  @extend .col-12;
}

.float-xs-left  { @extend .float-left; }
.float-xs-right { @extend .float-right; }
.float-xs-none  { @extend .float-none; }

@each $bp in map-keys($grid-breakpoints) {
  .hidden-#{$bp}-up {
    @include media-breakpoint-up($bp) {
      display: none !important;
    }
  }
  .hidden-#{$bp}-down {
    @include media-breakpoint-down($bp) {
      display: none !important;
    }
  }
}


.flex-first {
  -webkit-box-ordinal-group: 0;
  -webkit-order: -1;
  -ms-flex-order: -1;
  order: -1;
}

.flex-last {
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
}


.input-group-addon, .input-group-btn, .input-group .form-control {
  -js-display: flex;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.input-group-addon, .input-group-btn {
  white-space: nowrap;
  vertical-align: middle;
}
.input-group-btn {
  position: relative;
  font-size: 0;
  white-space: nowrap;
}

.input-group-btn>.btn {
  position: relative;
  flex: 1;
}

.pull-0{
  right:auto
}
.pull-1{
  right:8.33333%
}
.pull-2{
  right:16.66667%
}
.pull-3{
  right:25%
}
.pull-4{
  right:33.33333%
}
.pull-5{
  right:41.66667%
}
.pull-6{
  right:50%
}
.pull-7{
  right:58.33333%
}
.pull-8{
  right:66.66667%
}
.pull-9{
  right:75%
}
.pull-10{
  right:83.33333%
}
.pull-11{
  right:91.66667%
}
.pull-12{
  right:100%
}
.push-0{
  left:auto
}
.push-1{
  left:8.33333%
}
.push-2{
  left:16.66667%
}
.push-3{
  left:25%
}
.push-4{
  left:33.33333%
}
.push-5{
  left:41.66667%
}
.push-6{
  left:50%
}
.push-7{
  left:58.33333%
}
.push-8{
  left:66.66667%
}
.push-9{
  left:75%
}
.push-10{
  left:83.33333%
}
.push-11{
  left:91.66667%
}
.push-12{
  left:100%
}
.offset-1{
  margin-left:8.33333%
}
.offset-2{
  margin-left:16.66667%
}
.offset-3{
  margin-left:25%
}
.offset-4{
  margin-left:33.33333%
}
.offset-5{
  margin-left:41.66667%
}
.offset-6{
  margin-left:50%
}
.offset-7{
  margin-left:58.33333%
}
.offset-8{
  margin-left:66.66667%
}
.offset-9{
  margin-left:75%
}
.offset-10{
  margin-left:83.33333%
}
.offset-11{
  margin-left:91.66667%
}
@media (min-width:576px){
  .col-sm{
    flex-basis:0%;
    flex-grow:1;
    max-width:100%
  }
  .col-sm-auto{
    flex:0 0 auto;
    width:auto
  }
  .col-sm-1{
    flex:0 0 8.33333%;
    max-width:8.33333%
  }
  .col-sm-2{
    flex:0 0 16.66667%;
    max-width:16.66667%
  }
  .col-sm-3{
    flex:0 0 25%;
    max-width:25%
  }
  .col-sm-4{
    flex:0 0 33.33333%;
    max-width:33.33333%
  }
  .col-sm-5{
    flex:0 0 41.66667%;
    max-width:41.66667%
  }
  .col-sm-6{
    flex:0 0 50%;
    max-width:50%
  }
  .col-sm-7{
    flex:0 0 58.33333%;
    max-width:58.33333%
  }
  .col-sm-8{
    flex:0 0 66.66667%;
    max-width:66.66667%
  }
  .col-sm-9{
    flex:0 0 75%;
    max-width:75%
  }
  .col-sm-10{
    flex:0 0 83.33333%;
    max-width:83.33333%
  }
  .col-sm-11{
    flex:0 0 91.66667%;
    max-width:91.66667%
  }
  .col-sm-12{
    flex:0 0 100%;
    max-width:100%
  }
  .pull-sm-0{
    right:auto
  }
  .pull-sm-1{
    right:8.33333%
  }
  .pull-sm-2{
    right:16.66667%
  }
  .pull-sm-3{
    right:25%
  }
  .pull-sm-4{
    right:33.33333%
  }
  .pull-sm-5{
    right:41.66667%
  }
  .pull-sm-6{
    right:50%
  }
  .pull-sm-7{
    right:58.33333%
  }
  .pull-sm-8{
    right:66.66667%
  }
  .pull-sm-9{
    right:75%
  }
  .pull-sm-10{
    right:83.33333%
  }
  .pull-sm-11{
    right:91.66667%
  }
  .pull-sm-12{
    right:100%
  }
  .push-sm-0{
    left:auto
  }
  .push-sm-1{
    left:8.33333%
  }
  .push-sm-2{
    left:16.66667%
  }
  .push-sm-3{
    left:25%
  }
  .push-sm-4{
    left:33.33333%
  }
  .push-sm-5{
    left:41.66667%
  }
  .push-sm-6{
    left:50%
  }
  .push-sm-7{
    left:58.33333%
  }
  .push-sm-8{
    left:66.66667%
  }
  .push-sm-9{
    left:75%
  }
  .push-sm-10{
    left:83.33333%
  }
  .push-sm-11{
    left:91.66667%
  }
  .push-sm-12{
    left:100%
  }
  .offset-sm-0{
    margin-left:0
  }
  .offset-sm-1{
    margin-left:8.33333%
  }
  .offset-sm-2{
    margin-left:16.66667%
  }
  .offset-sm-3{
    margin-left:25%
  }
  .offset-sm-4{
    margin-left:33.33333%
  }
  .offset-sm-5{
    margin-left:41.66667%
  }
  .offset-sm-6{
    margin-left:50%
  }
  .offset-sm-7{
    margin-left:58.33333%
  }
  .offset-sm-8{
    margin-left:66.66667%
  }
  .offset-sm-9{
    margin-left:75%
  }
  .offset-sm-10{
    margin-left:83.33333%
  }
  .offset-sm-11{
    margin-left:91.66667%
  }
}
@media (min-width:768px){
  .col-md{
    flex-basis:0%;
    flex-grow:1;
    max-width:100%
  }
  .col-md-auto{
    flex:0 0 auto;
    width:auto
  }
  .col-md-1{
    flex:0 0 8.33333%;
    max-width:8.33333%
  }
  .col-md-2{
    flex:0 0 16.66667%;
    max-width:16.66667%
  }
  .col-md-3{
    flex:0 0 25%;
    max-width:25%
  }
  .col-md-4{
    flex:0 0 33.33333%;
    max-width:33.33333%
  }
  .col-md-5{
    flex:0 0 41.66667%;
    max-width:41.66667%
  }
  .col-md-6{
    flex:0 0 50%;
    max-width:50%
  }
  .col-md-7{
    flex:0 0 58.33333%;
    max-width:58.33333%
  }
  .col-md-8{
    flex:0 0 66.66667%;
    max-width:66.66667%
  }
  .col-md-9{
    flex:0 0 75%;
    max-width:75%
  }
  .col-md-10{
    flex:0 0 83.33333%;
    max-width:83.33333%
  }
  .col-md-11{
    flex:0 0 91.66667%;
    max-width:91.66667%
  }
  .col-md-12{
    flex:0 0 100%;
    max-width:100%
  }
  .pull-md-0{
    right:auto
  }
  .pull-md-1{
    right:8.33333%
  }
  .pull-md-2{
    right:16.66667%
  }
  .pull-md-3{
    right:25%
  }
  .pull-md-4{
    right:33.33333%
  }
  .pull-md-5{
    right:41.66667%
  }
  .pull-md-6{
    right:50%
  }
  .pull-md-7{
    right:58.33333%
  }
  .pull-md-8{
    right:66.66667%
  }
  .pull-md-9{
    right:75%
  }
  .pull-md-10{
    right:83.33333%
  }
  .pull-md-11{
    right:91.66667%
  }
  .pull-md-12{
    right:100%
  }
  .push-md-0{
    left:auto
  }
  .push-md-1{
    left:8.33333%
  }
  .push-md-2{
    left:16.66667%
  }
  .push-md-3{
    left:25%
  }
  .push-md-4{
    left:33.33333%
  }
  .push-md-5{
    left:41.66667%
  }
  .push-md-6{
    left:50%
  }
  .push-md-7{
    left:58.33333%
  }
  .push-md-8{
    left:66.66667%
  }
  .push-md-9{
    left:75%
  }
  .push-md-10{
    left:83.33333%
  }
  .push-md-11{
    left:91.66667%
  }
  .push-md-12{
    left:100%
  }
  .offset-md-0{
    margin-left:0
  }
  .offset-md-1{
    margin-left:8.33333%
  }
  .offset-md-2{
    margin-left:16.66667%
  }
  .offset-md-3{
    margin-left:25%
  }
  .offset-md-4{
    margin-left:33.33333%
  }
  .offset-md-5{
    margin-left:41.66667%
  }
  .offset-md-6{
    margin-left:50%
  }
  .offset-md-7{
    margin-left:58.33333%
  }
  .offset-md-8{
    margin-left:66.66667%
  }
  .offset-md-9{
    margin-left:75%
  }
  .offset-md-10{
    margin-left:83.33333%
  }
  .offset-md-11{
    margin-left:91.66667%
  }
}
@media (min-width:992px){
  .col-lg{
    flex-basis:0%;
    flex-grow:1;
    max-width:100%
  }
  .col-lg-auto{
    flex:0 0 auto;
    width:auto
  }
  .col-lg-1{
    flex:0 0 8.33333%;
    max-width:8.33333%
  }
  .col-lg-2{
    flex:0 0 16.66667%;
    max-width:16.66667%
  }
  .col-lg-3{
    flex:0 0 25%;
    max-width:25%
  }
  .col-lg-4{
    flex:0 0 33.33333%;
    max-width:33.33333%
  }
  .col-lg-5{
    flex:0 0 41.66667%;
    max-width:41.66667%
  }
  .col-lg-6{
    flex:0 0 50%;
    max-width:50%
  }
  .col-lg-7{
    flex:0 0 58.33333%;
    max-width:58.33333%
  }
  .col-lg-8{
    flex:0 0 66.66667%;
    max-width:66.66667%
  }
  .col-lg-9{
    flex:0 0 75%;
    max-width:75%
  }
  .col-lg-10{
    flex:0 0 83.33333%;
    max-width:83.33333%
  }
  .col-lg-11{
    flex:0 0 91.66667%;
    max-width:91.66667%
  }
  .col-lg-12{
    flex:0 0 100%;
    max-width:100%
  }
  .pull-lg-0{
    right:auto
  }
  .pull-lg-1{
    right:8.33333%
  }
  .pull-lg-2{
    right:16.66667%
  }
  .pull-lg-3{
    right:25%
  }
  .pull-lg-4{
    right:33.33333%
  }
  .pull-lg-5{
    right:41.66667%
  }
  .pull-lg-6{
    right:50%
  }
  .pull-lg-7{
    right:58.33333%
  }
  .pull-lg-8{
    right:66.66667%
  }
  .pull-lg-9{
    right:75%
  }
  .pull-lg-10{
    right:83.33333%
  }
  .pull-lg-11{
    right:91.66667%
  }
  .pull-lg-12{
    right:100%
  }
  .push-lg-0{
    left:auto
  }
  .push-lg-1{
    left:8.33333%
  }
  .push-lg-2{
    left:16.66667%
  }
  .push-lg-3{
    left:25%
  }
  .push-lg-4{
    left:33.33333%
  }
  .push-lg-5{
    left:41.66667%
  }
  .push-lg-6{
    left:50%
  }
  .push-lg-7{
    left:58.33333%
  }
  .push-lg-8{
    left:66.66667%
  }
  .push-lg-9{
    left:75%
  }
  .push-lg-10{
    left:83.33333%
  }
  .push-lg-11{
    left:91.66667%
  }
  .push-lg-12{
    left:100%
  }
  .offset-lg-0{
    margin-left:0
  }
  .offset-lg-1{
    margin-left:8.33333%
  }
  .offset-lg-2{
    margin-left:16.66667%
  }
  .offset-lg-3{
    margin-left:25%
  }
  .offset-lg-4{
    margin-left:33.33333%
  }
  .offset-lg-5{
    margin-left:41.66667%
  }
  .offset-lg-6{
    margin-left:50%
  }
  .offset-lg-7{
    margin-left:58.33333%
  }
  .offset-lg-8{
    margin-left:66.66667%
  }
  .offset-lg-9{
    margin-left:75%
  }
  .offset-lg-10{
    margin-left:83.33333%
  }
  .offset-lg-11{
    margin-left:91.66667%
  }
}
@media (min-width:1200px){
  .col-xl{
    flex-basis:0%;
    flex-grow:1;
    max-width:100%
  }
  .col-xl-auto{
    flex:0 0 auto;
    width:auto
  }
  .col-xl-1{
    flex:0 0 8.33333%;
    max-width:8.33333%
  }
  .col-xl-2{
    flex:0 0 16.66667%;
    max-width:16.66667%
  }
  .col-xl-3{
    flex:0 0 25%;
    max-width:25%
  }
  .col-xl-4{
    flex:0 0 33.33333%;
    max-width:33.33333%
  }
  .col-xl-5{
    flex:0 0 41.66667%;
    max-width:41.66667%
  }
  .col-xl-6{
    flex:0 0 50%;
    max-width:50%
  }
  .col-xl-7{
    flex:0 0 58.33333%;
    max-width:58.33333%
  }
  .col-xl-8{
    flex:0 0 66.66667%;
    max-width:66.66667%
  }
  .col-xl-9{
    flex:0 0 75%;
    max-width:75%
  }
  .col-xl-10{
    flex:0 0 83.33333%;
    max-width:83.33333%
  }
  .col-xl-11{
    flex:0 0 91.66667%;
    max-width:91.66667%
  }
  .col-xl-12{
    flex:0 0 100%;
    max-width:100%
  }
  .pull-xl-0{
    right:auto
  }
  .pull-xl-1{
    right:8.33333%
  }
  .pull-xl-2{
    right:16.66667%
  }
  .pull-xl-3{
    right:25%
  }
  .pull-xl-4{
    right:33.33333%
  }
  .pull-xl-5{
    right:41.66667%
  }
  .pull-xl-6{
    right:50%
  }
  .pull-xl-7{
    right:58.33333%
  }
  .pull-xl-8{
    right:66.66667%
  }
  .pull-xl-9{
    right:75%
  }
  .pull-xl-10{
    right:83.33333%
  }
  .pull-xl-11{
    right:91.66667%
  }
  .pull-xl-12{
    right:100%
  }
  .push-xl-0{
    left:auto
  }
  .push-xl-1{
    left:8.33333%
  }
  .push-xl-2{
    left:16.66667%
  }
  .push-xl-3{
    left:25%
  }
  .push-xl-4{
    left:33.33333%
  }
  .push-xl-5{
    left:41.66667%
  }
  .push-xl-6{
    left:50%
  }
  .push-xl-7{
    left:58.33333%
  }
  .push-xl-8{
    left:66.66667%
  }
  .push-xl-9{
    left:75%
  }
  .push-xl-10{
    left:83.33333%
  }
  .push-xl-11{
    left:91.66667%
  }
  .push-xl-12{
    left:100%
  }
}

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus{
  box-shadow: none;
}

.btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus, .show>.btn-secondary.dropdown-toggle:focus{
  box-shadow: none;
}

/*** 5 column layout ***/
.col-15,
.col-sm-15,
.col-md-15,
.col-lg-15,
.col-xl-15{
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.col-15 {
  flex: 0 0 20%;
  max-width: 20%;
}
@include media-breakpoint-only(sm) {
  .col-sm-15 {
    flex: 0 0 20%;
    max-width: 20%;
  }
}
@include media-breakpoint-only(md) {
  .col-md-15 {
    flex: 0 0 20%;
    max-width: 20%;
  }
}
@include media-breakpoint-only(lg) {
  .col-lg-15 {
    flex: 0 0 20%;
    max-width: 20%;
  }
}
@include media-breakpoint-only(xl) {
  .col-xl-15 {
    flex: 0 0 20%;
    max-width: 20%;
  }
}