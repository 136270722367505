button {
  border-radius: 0;
  -webkit-appearance: none;
  cursor: pointer;
  font: inherit;
  overflow: visible;
  text-transform: none;
  padding: 0;
}


.btn{
  cursor: pointer;
  padding: .5rem 1.25rem;
  &:focus{
    box-shadow: none;
  }
  &:disabled,
  &[disabled]{
    cursor: no-drop;
  }
}
.btn-primary:not(.btn-iconic),
.btn-secondary:not(.btn-iconic)  {
  i {
    margin-right: .5em;
  }
}

.btn-group-lg > .btn, .btn-lg{
  border-radius: 0;
  padding: 1rem 1.5rem;
  font-size: inherit;
}

.btn-group-sm>.btn, .btn-sm{
  padding: .25rem .5rem;
  border-radius: 0;
}

.btn-sm,
.btn-group-sm>.btn  {
  i {
    margin-right: 0 !important;
    vertical-align: middle;
  }
}

.btn.disabled, .btn:disabled,
.btn.disabled:hover {
  opacity: 0.5;
  cursor: not-allowed;
}


.btn-warning {
  @include transition(all .4s ease-in-out);
}
