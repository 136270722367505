//modal
.modal-backdrop{
  background-color: rgba(0, 0, 0, 0.5);
}
.modal{
  z-index: 999996;
}
.modal-backdrop{
  z-index: 999992;
  &.backdrop-second{
    z-index: 999989;
  }
}

.modal-dialog {
  max-width: 700px;
  margin: 90px auto;
  padding: 1rem;
  left: 0;
  right: 0;
}
.modal-backdrop.show{
  opacity: 1;
}
.modal-content{
  border-radius: 0;
  border: none;
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  opacity: 0;
  transition: all 0.3s;

}
.modal-open .modal{
  padding: 0!important;
}
.modal.fade .modal-dialog {
  transform: translateY(0);
}
.modal.show .modal-content{
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}
.modal-title {
  font-size: 1.5rem;
}

/*** Responsive part ***/

@include media-breakpoint-down(xs) {
  .modal-dialog {
    padding: 0;
    margin: 0;
  }
  .modal-content{
  min-height: 100vh;
  }
}