//footer
#footer {
  .footer-style-1 {
    .social-links > li {
      padding-bottom: 1rem;
    }
  }

  .footer-style-3 {
    .block-newsletter {
      text-align: center;
      > h5 {
        display: inline-block;
        margin: 1rem;
        vertical-align: top;
      }
      .ps-emailsubscription-block {
        display: inline-block;
        min-width: 270px;
      }
    }
  }
}

//first footer
#footer-container-first {
  padding: 1rem 0;

  .ps-emailsubscription-block {
    .input-subscription {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }
}

//main foooter
#footer-container-main {
  &.footer-style-1{
    .ps-emailsubscription-block{
      .gdpr_module, .ps-emailsubscription-conditions{
        max-width: 240px;
      }
    }
  }
  //style-4 and 5
  &.footer-style-inline {
    .block-toggle {
      margin-bottom: .4rem;
    }
    > div > .row {
      display: block;
      .block-title {
        margin: 0;
        margin-right: 1rem;
      }
      .block-content {
        vertical-align: top;
      }
      .block-title, .block-content {
        display: inline-block;
      }
      .block-links ul > li {
        display: inline-block;
      }
      .block-links ul > li a {
        padding: 0 1rem 0 0;
      }
      .block-links ul > li a:before {
        display: none;
      }
    }

    // block-contact
    .block-iqitcontactpage hr {
      display: none;
    }
    .contact-rich .part {
      display: inline-block;
    }
    .contact-rich > strong {
      vertical-align: top;
      margin: 0;
    }
  }

  //footer style 4
  &.footer-style-4 {
    text-align: center;
    .block-iqithtmlandbanners-html .block-content {
      margin-top: .5rem;
    }
  }

  //footer style 5
  &.footer-style-5 {
    .block-title {
      max-width: 10rem;
    }
    .block-content {
      width: calc(100% - 12rem);
    }
  }
}

//copyrights
#footer-copyrights {
  padding: 1rem 0;
  &._footer-copyrights-2 {
    text-align: center;
    .copyright-img, .copyright-txt {
      margin-bottom: 0.8rem;
    }
  }
  .copyright-txt > p:last-child {
    margin: 0;
  }
}

//responsive part
@include media-breakpoint-down(sm) {
  #wrapper {
    margin-bottom: 0 !important;
  }
  #footer {
    z-index: 1 !important;
    position: static !important;
  }
}

@include media-breakpoint-down(xs) {

  //footer style-2
  .footer-style-2 {
    .block-newsletter {
      margin-bottom: 1rem;
    }
    .block-social-links {
      text-align: center !important;
    }
  }

  //style-4 and 5
  #footer-container-main {
    &.footer-style-inline {
      .block-content {
        display: block;
        width: 100%;
        margin-top: 1rem;
      }
    }
  }

  //copyrights-1
  ._footer-copyrights-1 {
    .copyright-img {
      margin-bottom: 1rem;
      text-align: center !important;
    }
    .copyright-txt {
      text-align: center !important;
    }
  }

}


