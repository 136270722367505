input,
textarea {
  font-family: inherit;
}
input:required {
  box-shadow: none;
}
input::-ms-clear {
  display: none;
}
input[type="number"] {
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
select,
.form-control-select {
  background-image: linear-gradient(45deg, transparent 50%, currentColor 50%),
    linear-gradient(135deg, currentColor 50%, transparent 50%), linear-gradient(to right, transparent, transparent);
  background-position: calc(100% - 13px) calc(1em + 0px), calc(100% - 8px) calc(1em + 0px), 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
}

.custom-select2 {
  position: relative;
  transition: $default-transition;
  select,
  .form-control-select {
    background: none !important;
    position: relative;
    z-index: 2;
  }
  &:after {
    content: "\f107";
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    right: 0.6rem;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 13px;
  }
}
small.label,
small.value {
  font-size: 0.8rem;
}
.form-control-label {
  padding-top: 0.8rem;
}
.form-control {
  border: none;
  height: auto;
  padding: 0.6rem 1rem;
  transition: $default-transition;
  -webkit-appearance: none;
  &:focus {
    box-shadow: none;
  }
}

.input-group {
  &.focus {
    outline: none;
  }
  .form-control:focus {
    outline: none;
  }
  .input-group-btn > .btn {
    border: 0;
    box-shadow: none;
    font-weight: normal;
    margin-left: 0;
    padding: rem(10.5px) rem(16px);
    text-transform: uppercase;
  }
}
.form-control-select {
  height: rem(42px);
  -moz-appearance: none;
  -webkit-appearance: none;
  padding-right: rem(32px);
  &::-ms-expand {
    display: none;
  }
}
select.form-control:not([size]):not([multiple]) {
  height: rem(42px);
}
.form-control-valign {
  padding-top: rem(8px);
}
.form-control-comment {
  font-size: 0.8rem;
  opacity: 0.8;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}
.form-control-submit {
  &.disabled {
    background: $teal;
    color: white;
  }
}
.form-group {
  &.has-error {
    input,
    select {
      outline: rem(3px) solid $red;
    }
    .help-block {
      color: $red;
    }
  }
}
.group-span-filestyle {
  label {
    margin: 0;
  }
  .btn-default {
    background: $blue;
    color: white;
    text-transform: uppercase;
    border-radius: 0;
    font-size: rem(14px);
    padding: rem(8px) rem(16px);
  }
}
.bootstrap-touchspin {
  input {
    &:focus {
      outline: none;
    }
  }
  .btn-touchspin {
    @extend .btn-default;
    background-color: white;
    border: $input-btn-border-width solid $input-border-color;
    height: ($input-height / 2) + rem($input-btn-border-width);
    &:hover {
      background-color: $gray-light;
    }
  }
  .input-group-btn-vertical {
    width: auto;
    display: block;
    .bootstrap-touchspin-up {
      border-radius: 0;
      height: 50%;
    }
    .bootstrap-touchspin-down {
      border-radius: 0;
      height: 50%;
      border-top-color: transparent !important;
      margin-top: 0;
    }
    i {
      font-size: 1rem;
      left: 6px;
      pointer-events: none;
    }
  }
}
.radio-inline {
  margin-right: 0.8rem;
}
.custom-radio {
  display: inline-block;
  position: relative;
  width: 16px;
  height: 16px;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 50%;
  margin-right: 3px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.09);
  input[type="radio"] {
    opacity: 0;
    cursor: pointer;
  }
  input[type="radio"]:checked + span {
    -webkit-animation: checkbox-bounce 0.3s $smooth-transition-timing;
    -moz-animation: checkbox-bounce 0.3s $smooth-transition-timing;
    animation: checkbox-bounce 0.3s $smooth-transition-timing;
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
  label {
    display: inline;
  }
}
.custom-checkbox {
  position: relative;
  input[type="checkbox"] {
    margin-top: 4px;
    width: 16px;
    height: 16px;
    opacity: 0;
    z-index: 2;
    cursor: pointer;
    position: absolute;
    + span {
      margin-right: 3px;
      display: inline-block;
      width: 16px;
      height: 16px;
      vertical-align: middle;
      cursor: pointer;
      position: relative;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.09);
      text-align: center;
      .checkbox-checked {
        display: none;
        position: absolute;
        top: 2px;
        left: 1px;
        bottom: 0;
        right: 0;
        margin: auto;
        font-size: 10px;
      }
    }
    &:checked + span {
      .checkbox-checked {
        -webkit-animation: checkbox-bounce 0.3s $smooth-transition-timing;
        -moz-animation: checkbox-bounce 0.3s $smooth-transition-timing;
        animation: checkbox-bounce 0.3s $smooth-transition-timing;
        display: block;
      }
    }
  }
  label {
    text-align: left;
    display: inline;
  }
}

.input-color {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 1.5rem;
  width: 1.5rem;
}

.input-container {
  position: relative;
}

.input-radio {
  position: absolute;
  top: 0;
  cursor: pointer;
  opacity: 0;
  min-height: 1.5rem;
  min-width: 1.5rem;
  width: 100%;
  height: 100%;
  padding: 0 0.4rem;
  + span {
    padding: 2px 6px;
    line-height: calc(1.5rem - 4px);
  }
}

.custom-checkbox input[type="checkbox"] + span:not(.color),
.custom-radio {
  border: 1px solid $gray-light;
}

.input-color,
.input-radio {
  &:checked + span,
  &:hover + span {
    padding: 0 4px;
    border: 2px solid $gray-light;
    line-height: calc(1.5rem - 4px);
  }
}

.color,
.radio-label,
.custom-checkbox input[type="checkbox"] + span.color {
  width: 1.5rem;
  height: 1.5rem;
  display: inline-block;
  outline: 1px solid $gray-light;
  cursor: pointer;
  background-size: contain;
  text-align: center;
  line-height: 1.8rem;
  margin-right: 2px;
  &.active,
  &:hover {
    border: 2px solid $gray-light;
  }
}
.radio-label {
  width: auto;
  height: auto;
  min-height: 1.5rem;
  min-width: 1.5rem;
}
.facet-label {
  &.active,
  &:hover {
    .custom-checkbox span.color {
      border: 2px solid $gray-light;
    }
  }
}
