/*** SOME GENERIC STYLES ***/
@mixin customer-area-base-box {
  border: 1px solid $gray-light;
  padding: 1rem;
}

.table-labeled {
  th {
    vertical-align: middle;
  }
  td {
    vertical-align: middle;
  }
  .label {
    font-size: inherit;
    padding: rem(4px) rem(6px);
    margin: rem(2px);
    color: white;
    display: inline-block;
  }
}
.page-order {
  .table {
    margin-bottom: 0;
  }
  table {
    th {
      padding: rem(8px);
    }
    td {
      padding: rem(8px);
    }
    thead {
      th {
        text-align: center;
      }
    }
  }
}
#customer-form{
  .custom-checkbox {
    @include display(flex);
    span {
      flex: none
    }
    label {
      padding-left: 0.5rem;
    }
    em{
      font-size: .8rem;
      opacity: .8;
    }
  }
}

#authentication {
  .tooltip.tooltip-bottom {
    padding: 0;
    margin: 0;
  }
}
#identity,
#authentication {
  .radio-inline {
    padding: 0;
    .custom-radio {
      margin-right: 0;
    }
  }
}

/*** Most of the customer account pages ***/
.my-account-page-content-wrapper{
  @include customer-area-base-box;
  margin: 0 auto;
  //side menu of my account page
  .my-account-side-links{
    border-right: 1px solid $gray-light;
    > a{
      display: block;
      width: 100%;
      max-width: 100%;
      flex: 0;
      border-bottom: 1px solid $gray-light;
      padding: .6rem;
      &:last-child{ border-bottom: none}
    }
  }
}
.page-customer-account {
  #content {
    @include customer-area-base-box;
    margin: 0 auto;
    .order-actions {
      a {
        padding: 0 rem(2px);
      }
    }
    .forgot-password {
      text-align: center;
      font-size: $font-size-sm;
      margin-top: rem(16px);
      padding-bottom: rem(15px);
    }
    .no-account {
      text-align: center;
      font-size: $base-font-size;
    }

  }
}
/*** Login page ***/
.page-authentication {
  #content {
    @include customer-area-base-box;
    margin: 0 auto;
  }
}
/*** Addresses page ***/
.page-addresses {
  .address {
    border: 1px solid $gray-lighter;
    margin-bottom: rem(30px);
    .address-body {
      padding: rem(16px);
      h4 {
        font-weight: 700;
      }
      address {
        min-height: rem(144px);
      }
    }
    .address-footer {
      border-top: 1px solid $gray-lighter;
      padding: rem(8px) rem(16px);
      a {
        margin-right: rem(8px);
        span {
          vertical-align: middle;
        }
      }
    }
  }
  .addresses-footer {
    margin: 0 rem(15px);
    a {
      span {
        vertical-align: middle;
        margin-top: $small-space;
      }
    }
  }
}

/*** Order details page ***/
.page-order-detail {
  .box {
    @include customer-area-base-box;
    margin-bottom: rem(16px);
  }
  #order-infos, #order-infos2 {
    ul {
      margin: 0;
    }
  }

  #order-history {
    .history-lines {
      .history-line {
        padding: rem(8px) 0;
        border-bottom: 1px solid $gray-lighter;
        &:last-child {
          border-bottom: 0;
        }
        .label {
          display: inline-block;
          margin: rem(4px) 0;
          color: white;
          padding: rem(4px) rem(6px);
        }
      }
    }
  }
  .addresses {
    h4 {
      font-size: $base-font-size;
      font-weight: 700;
    }
  }
  #order-products {
    &.return {
      margin-bottom: rem(16px);
      th {
        &.head-checkbox {
          width: 30px;
        }
      }
      td {
        padding: 1.375rem 0.75rem;
        &.qty {
          min-width: 125px;
          .current {
            width: 30%;
            float: left;
            text-align: right;
            padding-right: 0.5rem;
          }
          .select {
            width: 70%;
            float: left;
            margin: -0.625rem 0;
            padding-left: 0.25rem;
            select {
              text-align: center;
            }
          }
        }
      }
    }
  }
  .order-items {
    padding: 0 !important;
    .order-item {
      padding: rem(16px) rem(16px) 0;
      border-bottom: 1px solid $gray-lighter;
      .checkbox {
        width: 30px;
        float: left;
        padding: 0 rem(15px);
      }
      .content {
        width: calc(100% - 30px);
        float: left;
        padding: 0 rem(15px);
      }
      .desc {
        .name {
          font-weight: bold;
        }
        margin-bottom: rem(16px);
      }
      .qty {
        margin-bottom: rem(16px);
        .q {
          margin-bottom: rem(4px);
        }
        .s {
          margin-bottom: rem(4px);
        }
      }
    }
  }
  .messages {
    .message {
      margin-top: rem(8px);
      border-bottom: 1px solid $gray-lighter;
      &:last-child {
        border-bottom: 0;
      }
      > div {
        margin-bottom: rem(8px);
      }
    }
  }
  .customization {
    margin-top: rem(12px);
  }
}
/*** Order return page ***/
#order-return-infos {
  .thead-default th {
    color: $gray-darker;
  }
  .customization {
    margin-top: rem(12px);
  }
}
/*** My account landing page ***/
.page-my-account {
  #content {
  }
}
/*** History page ***/
#history {
  .orders {
    margin: 0 rem(-16px);
    .order {
      a {
        h3 {
          color: $gray-dark;
        }
      }
      padding: rem(12px) rem(16px);
      border-bottom: 1px solid $gray-lighter;
      .label {
        display: inline-block;
        margin: rem(4px) 0;
        padding: rem(4px) rem(6px);
        color: white;
        border-radius: 3px;
      }
      &:last-child {
        border-bottom: 0;
      }
    }
  }
}


/*** PSGDPR***/
#module-psgdpr-gdpr .psgdprinfo17{
  overflow: hidden;
}

/*** FOOTER ***/

.login-form {
  margin-top: 15px;
}

/*** Responsive part ***/
@include media-breakpoint-down(xs) {
  .my-account-page-content-wrapper{
    //side menu of my account page
    .my-account-side-links{
      border-right: none;
      border-bottom: 1px solid $gray-lighter;
      padding-bottom: 1rem;
      margin-bottom: 1.6rem;
      margin-right: 3px;
      margin-left: 3px;
  }
  }
}

